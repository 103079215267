import React from 'react';

const Clock: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 6.66683C12.6362 6.66683 6.66668 12.6364 6.66668 20.0002C6.66668 27.364 12.6362 33.3335 20 33.3335C27.3638 33.3335 33.3333 27.364 33.3333 20.0002C33.3333 12.6364 27.3638 6.66683 20 6.66683ZM3.33334 20.0002C3.33334 10.7954 10.7953 3.3335 20 3.3335C29.2048 3.3335 36.6667 10.7954 36.6667 20.0002C36.6667 29.2049 29.2048 36.6668 20 36.6668C10.7953 36.6668 3.33334 29.2049 3.33334 20.0002ZM20 11.6668C20.9205 11.6668 21.6667 12.413 21.6667 13.3335V19.3098L26.1785 23.8217C26.8294 24.4725 26.8294 25.5278 26.1785 26.1787C25.5276 26.8295 24.4724 26.8295 23.8215 26.1787L18.8215 21.1787C18.5089 20.8661 18.3333 20.4422 18.3333 20.0002V13.3335C18.3333 12.413 19.0795 11.6668 20 11.6668Z"
        fill="#957BFF"
      />
    </svg>
  );
};

export default Clock;
