import React from 'react';
import Logo from '../svgs/Logo';
import './Head.css';
import ArrowDown from '../svgs/ArrowDown';

const Head: React.FC = () => {
  return (
    <div className="Head">
      <div className="Head__Background Head__BackgroundTop" />
      <div className="Head__Background Head__BackgroundImage" />
      <div className="Head__Wrapper">
        <div className="Head__LogoContainer">
          <a href="https://www.crazygames.com" title="CrazyGames.com" target="_blank" rel="noreferrer">
            <Logo />
          </a>
        </div>
        <div className="Head__Content">
          <div className="Head__SupTitle">Advertising</div>
          <h1>Reach a massive audience through web games</h1>
        </div>
        <div
          className="Head__ArrowContainer"
          onClick={() => {
            window.scroll({
              top: 840,
              behavior: 'smooth',
            });
          }}
        >
          <ArrowDown />
        </div>
      </div>
    </div>
  );
};

export default Head;
