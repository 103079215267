import React from 'react';
import './Brands.css';

const Brands: React.FC = () => {
  return (
    <div className="Brands">
      <div className="Brands__Title">BRANDS Seen on crazygames</div>
      <div className="Brands__Content">
        <img src="/images/brands/mattel-logo.png" alt="Mattel Logo" />
        <img src="/images/brands/hasbro-logo.png" alt="Hasbro Logo" />
        <img src="/images/brands/disney-logo.png" alt="Disney Logo" />
        <img src="/images/brands/moose-logo.png" alt="Moose Logo" />
        <img src="/images/brands/lego-logo.png" alt="Lego Logo" />
        <img src="/images/brands/nintendo-logo.png" alt="Nintendo Logo" />
        <img src="/images/brands/sonic2-logo.png" alt="Sonic2 Logo" />
      </div>
    </div>
  );
};

export default Brands;
