import React from 'react';

const Players: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6667 11.6667C16.6667 9.82572 18.1591 8.33333 20 8.33333C21.841 8.33333 23.3333 9.82572 23.3333 11.6667C23.3333 13.5076 21.841 15 20 15C18.1591 15 16.6667 13.5076 16.6667 11.6667ZM20 5C16.3181 5 13.3333 7.98477 13.3333 11.6667C13.3333 12.1515 13.3851 12.6243 13.4834 13.0797C12.583 12.2052 11.3544 11.6667 10 11.6667C7.23859 11.6667 5.00001 13.9052 5.00001 16.6667C5.00001 19.4281 7.23859 21.6667 10 21.6667C12.7614 21.6667 15 19.4281 15 16.6667C15 16.4512 14.9864 16.2389 14.9599 16.0305C16.1823 17.4411 17.987 18.3333 20 18.3333C22.0131 18.3333 23.8177 17.4411 25.0401 16.0305C25.0136 16.2389 25 16.4512 25 16.6667C25 19.4281 27.2386 21.6667 30 21.6667C32.7614 21.6667 35 19.4281 35 16.6667C35 13.9052 32.7614 11.6667 30 11.6667C28.6456 11.6667 27.417 12.2052 26.5166 13.0797C26.6149 12.6243 26.6667 12.1515 26.6667 11.6667C26.6667 7.98477 23.6819 5 20 5ZM15 28.3333C15 25.5719 17.2386 23.3333 20 23.3333C22.7614 23.3333 25 25.5719 25 28.3333V31.6667H15V28.3333ZM20 20C23.0108 20 25.6487 21.5967 27.1131 23.9896C27.9869 23.5693 28.9666 23.3333 30 23.3333C33.6819 23.3333 36.6667 26.3181 36.6667 30V33.3333C36.6667 34.2538 35.9205 35 35 35H26.6667H13.3333H5.00001C4.07954 35 3.33334 34.2538 3.33334 33.3333V30C3.33334 26.3181 6.31811 23.3333 10 23.3333C11.0334 23.3333 12.0131 23.5693 12.8869 23.9896C14.3513 21.5967 16.9892 20 20 20ZM33.3333 31.6667H28.3333V28.3333V27.1122C28.8235 26.8284 29.3918 26.6667 30 26.6667C31.841 26.6667 33.3333 28.159 33.3333 30V31.6667ZM11.6667 28.3333V27.1122C11.1765 26.8284 10.6082 26.6667 10 26.6667C8.15906 26.6667 6.66668 28.159 6.66668 30V31.6667H11.6667V28.3333ZM30 15C29.0795 15 28.3333 15.7462 28.3333 16.6667C28.3333 17.5871 29.0795 18.3333 30 18.3333C30.9205 18.3333 31.6667 17.5871 31.6667 16.6667C31.6667 15.7462 30.9205 15 30 15ZM8.33334 16.6667C8.33334 15.7462 9.07954 15 10 15C10.9205 15 11.6667 15.7462 11.6667 16.6667C11.6667 17.5871 10.9205 18.3333 10 18.3333C9.07954 18.3333 8.33334 17.5871 8.33334 16.6667Z"
        fill="#957BFF"
      />
    </svg>
  );
};

export default Players;
