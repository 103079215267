import React from 'react';

const LinkedInCircle: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
      <circle cx="17.5" cy="17.5" r="16.5" stroke="#2F3148" strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6253 15.2898H18.3334V16.6388C18.7235 15.8629 19.7239 15.1659 21.2268 15.1659C24.1077 15.1659 24.7916 16.7102 24.7916 19.5438V24.7916H21.875V20.1891C21.875 18.5754 21.4849 17.6654 20.4918 17.6654C19.1144 17.6654 18.542 18.6462 18.542 20.1884V24.7916H15.6253V15.2898ZM10.6239 24.6676H13.5406V15.1659H10.6239V24.6676ZM13.9584 12.0676C13.9585 12.3121 13.91 12.5542 13.8158 12.7797C13.7215 13.0053 13.5834 13.2099 13.4094 13.3816C13.0567 13.732 12.5794 13.9282 12.0823 13.927C11.586 13.9267 11.1098 13.731 10.7566 13.3823C10.5833 13.21 10.4456 13.0052 10.3515 12.7796C10.2574 12.554 10.2087 12.3121 10.2083 12.0676C10.2083 11.574 10.4052 11.1015 10.7574 10.7529C11.1102 10.4038 11.5866 10.208 12.083 10.2083C12.5803 10.2083 13.0572 10.4044 13.4094 10.7529C13.7608 11.1015 13.9584 11.574 13.9584 12.0676Z"
        fill="#F9FAFF"
      />
    </svg>
  );
};

export default LinkedInCircle;
