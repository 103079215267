import React from 'react';
import Arcade from '../svgs/Arcade';
import Clock from '../svgs/Clock';
import Earth from '../svgs/Earth';
import Eye from '../svgs/Eye';
import Laptop from '../svgs/Laptop';
import Players from '../svgs/Players';
import Rise from '../svgs/Rise';
import Video from '../svgs/Video';
import './Body.css';

const Body: React.FC = () => {
  return (
    <div className="Body">
      <div className="Body__LeftContainer">
        <h2>Millions of users, billions of plays!</h2>
        <ul className="Body__LeftContainer__Content body_text">
          <li>
            <Players /> <p>20M monthly users</p>
          </li>
          <li>
            <Arcade /> <p>4B+ gameplays</p>
          </li>
          <li>
            <Clock /> <p>20 minute average session time</p>
          </li>
          <li>
            <Laptop /> <p>Desktop (80%) & mobile (20%) traffic</p>
          </li>
          <li>
            <Eye /> <p>650M+ monthly display impressions</p>
          </li>
          <li>
            <Video /> <p>50M+ monthly video impressions</p>
          </li>
        </ul>
        <button
          className="Body__Button Body__LeftContainer__Content__Button"
          onClick={() => {
            document.querySelector('.Footer')?.scrollIntoView({
              behavior: 'smooth',
            });
          }}
        >
          Get started
        </button>
      </div>

      <div className="Body__RightContainer">
        <div className="Body__RightContainer__Box">
          <h3 className="Body__RightContainer__Box__Head">
            <Earth /> Global footprint
          </h3>
          <p>Reach gamers in every region through our 20+ localized language domains.</p>
        </div>

        <div className="Body__RightContainer__Box">
          <h3 className="Body__RightContainer__Box__Head">
            <Rise /> Effective attention capture
          </h3>
          <p>Ensure your campaign is viewed everytime with 90%+ viewable ad units and 85%+ VCR.</p>
        </div>

        <div className="Body__RightContainer__Box">
          <h3 className="Body__RightContainer__Box__Head">
            <Earth /> Transparent targeting
          </h3>
          <p>
            Access to a dedicated account manager providing optimizations for your campaigns based on your targeting preferences: gaming
            tags, game, category, and demographic analytics.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Body;
