import React from 'react';

const Eye: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 16.6668C18.1591 16.6668 16.6667 18.1592 16.6667 20.0002C16.6667 21.8411 18.1591 23.3335 20 23.3335C21.841 23.3335 23.3333 21.8411 23.3333 20.0002C23.3333 18.1592 21.841 16.6668 20 16.6668ZM13.3333 20.0002C13.3333 16.3183 16.3181 13.3335 20 13.3335C23.6819 13.3335 26.6667 16.3183 26.6667 20.0002C26.6667 23.6821 23.6819 26.6668 20 26.6668C16.3181 26.6668 13.3333 23.6821 13.3333 20.0002Z"
        fill="#957BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6279 13.3997C9.10937 15.3564 7.09365 17.7185 5.89446 19.2838C5.56218 19.7175 5.56218 20.2822 5.89446 20.7159C7.09365 22.2812 9.10937 24.6433 11.6279 26.6C14.1652 28.5713 17.0428 29.9998 20 29.9998C22.9572 29.9998 25.8349 28.5713 28.3722 26.6C30.8907 24.6433 32.9064 22.2812 34.1056 20.7159C34.4379 20.2822 34.4379 19.7175 34.1056 19.2838C32.9064 17.7185 30.8907 15.3564 28.3722 13.3997C25.8349 11.4283 22.9572 9.99984 20 9.99984C17.0428 9.99984 14.1652 11.4283 11.6279 13.3997ZM9.58279 10.7674C12.3952 8.58233 15.9876 6.6665 20 6.6665C24.0125 6.6665 27.6048 8.58233 30.4173 10.7674C33.2485 12.9671 35.4623 15.5736 36.7517 17.2566C38.0003 18.8864 38.0003 21.1132 36.7517 22.7431C35.4623 24.4261 33.2485 27.0325 30.4173 29.2322C27.6048 31.4173 24.0125 33.3332 20 33.3332C15.9876 33.3332 12.3952 31.4173 9.58279 29.2322C6.75154 27.0325 4.53777 24.4261 3.24839 22.7431C1.99976 21.1132 1.99976 18.8864 3.24839 17.2566C4.53777 15.5736 6.75154 12.9671 9.58279 10.7674Z"
        fill="#957BFF"
      />
    </svg>
  );
};

export default Eye;
