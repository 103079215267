import React from 'react';
import Head from './components/1_Head/Head';
import Brands from './components/2_Brands/Brands';
import Body from './components/3_Body/Body';
import GenZUsers from './components/4_GenZUsers/GenZUsers';
import PartnerAgencies from './components/5_PartnerAgencies/PartnerAgencies';
import Footer from './components/6_Footer/Footer';
import './styles/App.css';

const App: React.FunctionComponent = () => {
  return (
    <div>
      <Head />
      <Brands />
      <Body />
      <GenZUsers />
      <PartnerAgencies />
      <Footer />
    </div>
  );
};

export default App;
