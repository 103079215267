import React from 'react';

const Earth: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.8584 20.8332H10.4167C13.8684 20.8332 16.6667 23.6314 16.6667 27.0832V29.1665C16.6667 30.3171 17.5994 31.2498 18.75 31.2498C22.2018 31.2498 25 34.0481 25 37.4998V41.6665C26.4387 41.6665 27.8349 41.4842 29.1667 41.1414V37.4998C29.1667 34.0481 31.9649 31.2498 35.4167 31.2498H40.4552C41.2364 29.3198 41.6667 27.21 41.6667 24.9998C41.6667 22.7896 41.2364 20.6799 40.4552 18.7498L39.5833 18.7498C38.4327 18.7498 37.5 19.6826 37.5 20.8332C37.5 24.285 34.7018 27.0832 31.25 27.0832C27.7982 27.0832 25 24.285 25 20.8332C25 19.6826 24.0672 18.7498 22.9167 18.7498H21.875C18.0214 18.7498 14.8661 15.7605 14.6013 11.9743C11.8235 14.1948 9.77153 17.2854 8.8584 20.8332ZM15.7409 6.33197C8.88101 9.74096 4.16666 16.8199 4.16666 24.9998C4.16666 36.5058 13.4941 45.8332 25 45.8332C36.5059 45.8332 45.8333 36.5058 45.8333 24.9998C45.8333 21.6746 45.0543 18.5313 43.6687 15.7425C43.6681 15.7412 43.6674 15.7399 43.6668 15.7387C40.2574 8.87989 33.1791 4.1665 25 4.1665C21.674 4.1665 18.5301 4.94588 15.7409 6.33197ZM18.75 9.54466V11.4582C18.75 13.1841 20.1491 14.5832 21.875 14.5832H22.9167C26.3684 14.5832 29.1667 17.3814 29.1667 20.8332C29.1667 21.9838 30.0994 22.9165 31.25 22.9165C32.4006 22.9165 33.3333 21.9838 33.3333 20.8332C33.3333 17.8771 35.3856 15.4003 38.1431 14.7499C35.0929 10.8442 30.3397 8.33317 25 8.33317C22.7898 8.33317 20.6801 8.76339 18.75 9.54466ZM38.0112 35.4165H35.4167C34.2661 35.4165 33.3333 36.3492 33.3333 37.4998V39.4368C35.1322 38.3962 36.7185 37.0291 38.0112 35.4165ZM20.8333 41.1414C13.6449 39.2913 8.33332 32.7658 8.33332 24.9998H10.4167C11.5672 24.9998 12.5 25.9326 12.5 27.0832V29.1665C12.5 32.6183 15.2982 35.4165 18.75 35.4165C19.9006 35.4165 20.8333 36.3492 20.8333 37.4998V41.1414Z"
        fill="#957BFF"
      />
    </svg>
  );
};

export default Earth;
