import React from 'react';

const TwitterCircle: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
      <circle cx="17.5" cy="17.5" r="16.5" stroke="#2F3148" strokeWidth="2" />
      <path
        d="M25.9897 12.3499C25.3808 12.6197 24.7268 12.802 24.0392 12.8844C24.7486 12.4599 25.2794 11.7917 25.5325 11.0046C24.8659 11.4006 24.1364 11.6793 23.3756 11.8286C22.864 11.2823 22.1864 10.9203 21.4479 10.7986C20.7095 10.677 19.9515 10.8025 19.2917 11.1558C18.6319 11.509 18.1072 12.0702 17.7991 12.7522C17.4909 13.4342 17.4165 14.1989 17.5875 14.9275C16.2369 14.8597 14.9156 14.5087 13.7094 13.8972C12.5032 13.2857 11.439 12.4274 10.586 11.378C10.2944 11.8811 10.1267 12.4644 10.1267 13.0857C10.1263 13.6449 10.2641 14.1956 10.5276 14.6889C10.7912 15.1822 11.1724 15.6028 11.6375 15.9134C11.0981 15.8962 10.5706 15.7505 10.099 15.4883V15.532C10.0989 16.3164 10.3702 17.0767 10.8669 17.6838C11.3636 18.2909 12.055 18.7075 12.8239 18.8629C12.3235 18.9983 11.7989 19.0182 11.2897 18.9212C11.5066 19.5961 11.9292 20.1863 12.4982 20.6092C13.0672 21.032 13.7542 21.2663 14.463 21.2793C13.2598 22.2239 11.7738 22.7363 10.2441 22.734C9.97309 22.7341 9.70235 22.7183 9.43323 22.6866C10.986 23.685 12.7935 24.2148 14.6395 24.2128C20.8884 24.2128 24.3046 19.0371 24.3046 14.5484C24.3046 14.4025 24.3009 14.2553 24.2944 14.1094C24.9589 13.6289 25.5324 13.0338 25.9882 12.3521L25.9897 12.3499Z"
        fill="#F9FAFF"
      />
    </svg>
  );
};

export default TwitterCircle;
