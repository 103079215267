import * as React from 'react';
import './GenZUsers.css';

const GenZUsers: React.FC = () => {
  return (
    <div className="GenZUsers">
      <img src="/images/kid-playing-games.png" alt="Kid playing CrazyGames" />
      <div className="GenZUsers__RightContainer">
        <h2 className="GenZUsers__RightContainer__Title">Gen Z users</h2>
        <p>
          CrazyGames is the perfect place for the latest brands to reach a young, responsive audience. 65% of our users are teenagers aged
          13-18, and over 80% of our players are gen Z.
        </p>
      </div>
    </div>
  );
};

export default GenZUsers;
