import React from 'react';

const Laptop: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66666 6.66667C6.66666 5.74619 7.41285 5 8.33333 5H31.6667C32.5871 5 33.3333 5.74619 33.3333 6.66667V23.0629L36.5811 32.8063C36.7505 33.3145 36.6653 33.8732 36.3521 34.3079C36.0388 34.7425 35.5357 35 35 35H4.99999C4.46425 35 3.96117 34.7425 3.64792 34.3079C3.33467 33.8732 3.24944 33.3145 3.41885 32.8063L6.66666 23.0629V6.66667ZM9.53459 25L7.31237 31.6667H13.3333C13.3333 30.7462 14.0795 30 15 30H25C25.9205 30 26.6667 30.7462 26.6667 31.6667H32.6876L30.4654 25H9.53459ZM30 21.6667V8.33333H9.99999V21.6667H30Z"
        fill="#957BFF"
      />
    </svg>
  );
};

export default Laptop;
