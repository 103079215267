import * as React from 'react';
import LinkedInCircle from '../svgs/LinkedInCircle';
import LogoBlack from '../svgs/LogoBlack';
import TikTokCircle from '../svgs/TikTokCircle';
import TwitterCircle from '../svgs/TwitterCircle';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <>
      <div className="Footer">
        <h2>Get in touch</h2>
        <p className="Footer__Text">
          Blue chip brands have trusted CrazyGames to deliver impactful campaigns to gamers for over 8 years. Get in touch if you want to
          learn more.
        </p>
        <a href="https://www.crazygames.com/contact" target="_blank" rel="noreferrer">
          <button className="Footer__Button">Contact us</button>
        </a>
      </div>
      <div className="Footer__Bottom">
        <div className="Footer__Bottom__Wrapper">
          <a href="https://www.crazygames.com" title="CrazyGames.com" target="_blank" rel="noreferrer">
            <LogoBlack />
          </a>
          <div className="Footer__Bottom__SocialContainer">
            <a href="https://www.tiktok.com/@crazygames" title="CrazyGames TikTok" target="_blank" rel="noreferrer">
              <TikTokCircle />
            </a>
            <a href="https://www.linkedin.com/company/crazygames/" title="CrazyGames LinkedIn" target="_blank" rel="noreferrer">
              <LinkedInCircle />
            </a>
            <a href="https://twitter.com/crazygamescom" title="CrazyGames Twitter" target="_blank" rel="noreferrer">
              <TwitterCircle />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
