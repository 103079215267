import React from 'react';

const Logo: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="54" viewBox="0 0 150 54" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.3623 13.3651C64.3623 14.2028 64.6417 14.8531 65.1998 15.3157C65.7575 15.7792 66.3506 16.0102 66.9789 16.0102C67.6066 16.0102 68.1943 15.8281 68.7433 15.4632C69.2913 15.099 69.7347 14.9166 70.0736 14.9166C70.6721 14.9166 71.3996 15.4389 72.2562 16.483C72.7151 17.0549 72.9441 17.567 72.9441 18.0199C72.9441 18.7689 72.3957 19.468 71.2994 20.1183C70.1634 20.9066 68.7678 21.3007 67.1137 21.3007C65.2596 21.3007 63.4754 20.6407 61.7615 19.3205C60.9439 18.6898 60.2758 17.8474 59.7578 16.793C59.2393 15.7393 58.9805 14.5766 58.9805 13.3059C58.9805 12.0352 59.2393 10.8825 59.7578 9.84803C60.2758 8.81392 60.9439 7.98152 61.7615 7.35049C63.4152 6.05021 65.1796 5.3999 67.0536 5.3999C67.8107 5.3999 68.5486 5.50358 69.2664 5.71027C69.9839 5.91729 70.512 6.11933 70.8516 6.31638L71.3297 6.61179C71.6884 6.84839 71.9674 7.03547 72.1671 7.17337C72.6852 7.56748 72.9441 8.01541 72.9441 8.51818C72.9441 9.02061 72.735 9.58685 72.3167 10.2172C71.5983 11.3012 70.9111 11.8428 70.2532 11.8428C69.8743 11.8428 69.3363 11.6265 68.6387 11.1928C68.2797 10.8579 67.7663 10.6904 67.0986 10.6904C66.4303 10.6904 65.808 10.9267 65.2294 11.3995C64.6511 11.8724 64.3623 12.5277 64.3623 13.3651Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.5924 5.75501C85.9713 5.87297 86.285 6.07567 86.5348 6.36078C86.7836 6.64689 86.9083 7.1048 86.9083 7.73517C86.9083 8.36553 86.719 9.05538 86.3405 9.80405C85.9616 10.5527 85.4136 10.9269 84.6961 10.9269C84.3374 10.9269 83.9931 10.8485 83.664 10.691C83.3352 10.5331 82.9015 10.4544 82.3633 10.4544C81.8253 10.4544 81.3214 10.6418 80.8534 11.0156C80.3847 11.3904 80.1507 11.843 80.1507 12.375V18.582C80.1507 19.0353 80.1356 19.3749 80.106 19.6015C80.0758 19.8285 79.981 20.089 79.8216 20.3844C79.5224 20.8968 78.7454 21.153 77.4894 21.153C76.5326 21.153 75.8447 20.9859 75.4262 20.6506C75.1074 20.3751 74.9272 19.9514 74.8876 19.3799V18.5229V8.14888C74.8876 7.69596 74.903 7.35601 74.9326 7.12939C74.9629 6.90309 75.0476 6.65188 75.1868 6.37541C75.4658 5.84373 76.2431 5.57756 77.5197 5.57756C78.7152 5.57756 79.4632 5.80419 79.7618 6.25744C79.981 6.57279 80.0906 6.89778 80.0906 7.2324C80.1908 7.09483 80.3343 6.92735 80.5246 6.7303C80.7135 6.53358 81.1173 6.26741 81.7352 5.93212C82.3528 5.5975 82.8961 5.42969 83.3648 5.42969C83.8335 5.42969 84.2268 5.45494 84.5465 5.50379C84.8649 5.5533 85.2139 5.63671 85.5924 5.75501Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.4402 13.3798C92.4402 14.0298 92.6991 14.6163 93.2178 15.138C93.7356 15.6604 94.3639 15.9209 95.1012 15.9209C95.8385 15.9209 96.4517 15.6554 96.9405 15.1231C97.429 14.5914 97.6724 14.0052 97.6724 13.3649C97.6724 12.7245 97.4384 12.1284 96.9701 11.5764C96.5014 11.0255 95.8738 10.749 95.0864 10.749C94.2987 10.749 93.6609 11.0255 93.1728 11.5764C92.6843 12.1284 92.4402 12.7295 92.4402 13.3798ZM97.8822 7.23199C98.0019 6.12909 98.7889 5.57715 100.244 5.57715C101.022 5.57715 101.6 5.63597 101.978 5.7546C102.357 5.87256 102.621 6.07958 102.771 6.37499C102.92 6.67074 103.01 6.93226 103.04 7.15822C103.07 7.38485 103.085 7.72479 103.085 8.17804V18.5225C103.085 18.9754 103.07 19.3153 103.04 19.5416C103.01 19.7686 102.925 20.0294 102.786 20.3248C102.507 20.8376 101.824 21.0934 100.738 21.0934C99.6513 21.0934 98.9234 20.9602 98.5549 20.6947C98.1861 20.4285 97.9716 20.0397 97.9121 19.527C97.1742 20.5717 95.943 21.0934 94.2193 21.0934C92.495 21.0934 90.8947 20.3152 89.4201 18.7587C87.9446 17.2022 87.2073 15.3942 87.2073 13.3353C87.2073 11.2767 87.9499 9.47367 89.4349 7.92682C90.9202 6.38031 92.5494 5.60639 94.3242 5.60639C95.0017 5.60639 95.6196 5.72502 96.178 5.96128C96.7354 6.19788 97.1248 6.41952 97.3443 6.62654C97.5628 6.83323 97.7424 7.03527 97.8822 7.23199Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.341 15.9511H118.454C119.251 15.9511 119.825 16.0498 120.173 16.2466C120.522 16.4436 120.757 16.7297 120.876 17.1039C120.996 17.4784 121.056 17.9708 121.056 18.5816C121.056 19.1924 120.996 19.6852 120.876 20.059C120.757 20.4335 120.548 20.6947 120.248 20.8426C119.949 20.9901 119.685 21.0788 119.456 21.1084C119.227 21.1376 118.883 21.1526 118.424 21.1526H107.63C106.932 21.1526 106.324 20.8967 105.806 20.3843C105.287 19.8726 105.029 19.2612 105.029 18.552C105.029 17.8422 105.287 17.2368 105.806 16.734C106.324 16.2319 107.122 15.5079 108.198 14.5618C109.275 13.6164 110.251 12.7644 111.128 12.0058C112.005 11.2475 112.484 10.8284 112.564 10.7494H108.347C107.212 10.7494 106.494 10.5626 106.195 10.1878C105.855 9.75479 105.686 9.18291 105.686 8.47378C105.686 7.76433 105.736 7.23731 105.836 6.89271C105.936 6.54812 106.055 6.29159 106.195 6.12411C106.334 5.95696 106.533 5.82405 106.792 5.72535C107.131 5.62699 107.65 5.57715 108.347 5.57715H118.066C118.763 5.57715 119.371 5.82405 119.89 6.31651C120.408 6.80897 120.667 7.42472 120.667 8.16342C120.667 8.90212 120.368 9.55741 119.77 10.129C119.172 10.7005 117.029 12.6408 113.341 15.9511Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.996 21.0939C127.982 21.0939 126.313 20.3399 124.988 18.8329C123.662 17.3256 122.999 15.4887 122.999 13.3208V8.1489C122.999 7.67604 123.014 7.33145 123.044 7.11479C123.074 6.8978 123.159 6.64193 123.298 6.34619C123.577 5.81451 124.355 5.54834 125.631 5.54834C127.025 5.54834 127.843 5.92284 128.083 6.67117C128.202 6.98652 128.262 7.48896 128.262 8.17847V13.3503C128.262 14.1585 128.496 14.7889 128.965 15.2421C129.433 15.6957 130.06 15.9217 130.849 15.9217C131.636 15.9217 132.273 15.6854 132.763 15.2125C133.25 14.7397 133.494 14.119 133.494 13.3503V8.11932C133.494 7.6664 133.51 7.32646 133.539 7.09984C133.57 6.87354 133.664 6.61202 133.824 6.31661C134.082 5.80421 134.85 5.54834 136.126 5.54834C137.382 5.54834 138.149 5.81451 138.428 6.34619C138.568 6.64193 138.652 6.90312 138.683 7.12941C138.713 7.35604 138.728 7.69598 138.728 8.1489V18.6113C138.728 18.906 138.721 19.1529 138.707 19.352C138.7 19.4457 138.728 23.9905 138.683 24.6415C138.667 24.8694 138.558 25.1193 138.398 25.3948C138.099 25.9075 137.332 26.1637 136.097 26.1637C134.88 26.1637 134.133 25.9268 133.853 25.4539C133.693 25.1861 133.598 24.8738 133.566 24.5172C133.558 24.4281 133.554 19.2968 133.554 19.2024C133.475 19.3403 133.295 19.5374 133.016 19.7936C132.737 20.0498 132.468 20.2661 132.209 20.4439C131.531 20.8775 130.793 21.0939 129.996 21.0939Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.0934 34.9187C64.0934 35.559 64.3476 36.1352 64.8563 36.6476C65.3642 37.16 65.9815 37.4162 66.71 37.4162C67.4372 37.4162 68.0404 37.1547 68.5185 36.6326C68.9975 36.1109 69.2369 35.5394 69.2369 34.9187C69.2369 34.2979 69.0069 33.7171 68.5487 33.1748C68.0901 32.6331 67.4725 32.362 66.6946 32.362C65.9176 32.362 65.2893 32.6278 64.8112 33.1598C64.3331 33.6925 64.0934 34.2787 64.0934 34.9187ZM69.4158 40.9625C68.658 41.9477 67.452 42.4402 65.7979 42.4402C64.1428 42.4402 62.593 41.6866 61.1477 40.1793C59.7023 38.672 58.9805 36.9138 58.9805 34.9037C58.9805 32.894 59.7077 31.1311 61.1631 29.6135C62.6179 28.0966 64.2128 27.338 65.9472 27.338C66.6247 27.338 67.2426 27.4612 67.801 27.7075C68.3591 27.9537 68.7434 28.1803 68.9525 28.387C69.1616 28.5937 69.3257 28.7861 69.446 28.9632C69.5059 28.4119 69.7096 27.9929 70.0589 27.7075C70.4072 27.4217 70.9707 27.2788 71.7483 27.2788C72.5259 27.2788 73.0938 27.338 73.4525 27.4559C73.8112 27.5742 74.0654 27.7766 74.215 28.0617C74.3646 28.3478 74.4544 28.5987 74.4846 28.8154C74.5142 29.0327 74.529 29.3673 74.529 29.8202V42.0561C74.529 44.3217 73.7319 46.1496 72.1369 47.5386C70.5424 48.9276 68.7477 49.6221 66.7544 49.6221C65.2399 49.6221 63.7942 49.2573 62.4192 48.5286C61.0434 47.7995 60.3559 47.1392 60.3559 46.5484C60.3559 45.7801 60.6548 45.1006 61.2532 44.5091C61.6311 44.095 61.9256 43.8096 62.1351 43.6521C62.3442 43.4942 62.5879 43.4155 62.8676 43.4155C63.1464 43.4155 63.4358 43.5338 63.735 43.7701C64.6915 44.4988 65.7273 44.8636 66.8442 44.8636C67.6016 44.8636 68.2192 44.5682 68.6983 43.9774C69.1764 43.3859 69.4158 42.6768 69.4158 41.849V40.9625Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.7056 35.1402C81.7056 35.7905 81.9645 36.377 82.4836 36.8987C83.0013 37.4208 83.6293 37.6816 84.3673 37.6816C85.1046 37.6816 85.7178 37.4161 86.2059 36.8838C86.6944 36.3518 86.9385 35.7659 86.9385 35.1253C86.9385 34.4853 86.7038 33.8888 86.2362 33.3372C85.7675 32.7859 85.1392 32.5098 84.3525 32.5098C83.5641 32.5098 82.9263 32.7859 82.4388 33.3372C81.9497 33.8888 81.7056 34.4903 81.7056 35.1402ZM87.148 28.9927C87.2673 27.8898 88.055 27.3379 89.5104 27.3379C90.2874 27.3379 90.8656 27.3967 91.2445 27.515C91.6231 27.6333 91.8873 27.84 92.0369 28.1357C92.1866 28.4315 92.2756 28.6927 92.3059 28.9186C92.3362 29.1456 92.351 29.4855 92.351 29.9384V40.2829C92.351 40.7361 92.3362 41.0761 92.3059 41.3024C92.2756 41.529 92.1909 41.7902 92.0517 42.0856C91.7727 42.598 91.0899 42.8542 90.0033 42.8542C88.9167 42.8542 88.1895 42.7206 87.8204 42.4551C87.4515 42.1889 87.2377 41.8001 87.1775 41.2877C86.4403 42.3321 85.2091 42.8542 83.4848 42.8542C81.7604 42.8542 80.1608 42.0756 78.6855 40.5191C77.21 38.9626 76.4734 37.1546 76.4734 35.0957C76.4734 33.0371 77.2153 31.2344 78.7007 29.6872C80.1856 28.1411 81.8155 27.3671 83.5896 27.3671C84.2671 27.3671 84.885 27.4854 85.4434 27.7217C86.0012 27.9586 86.3902 28.1803 86.6097 28.387C86.8289 28.5936 87.0081 28.796 87.148 28.9927Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.8853 29.4062C100.822 28.0076 101.949 27.3081 103.264 27.3081C105.298 27.3081 106.812 28.1651 107.81 29.8794C108.028 29.5837 108.292 29.2736 108.602 28.9483C108.911 28.6233 109.404 28.2681 110.082 27.8843C110.759 27.5002 111.457 27.3081 112.175 27.3081C113.77 27.3081 115.105 27.9139 116.181 29.1254C117.258 30.3373 117.796 32.3424 117.796 35.14V40.3119C117.796 40.7658 117.781 41.1054 117.751 41.3317C117.722 41.5583 117.627 41.8192 117.467 42.1149C117.208 42.647 116.441 42.9128 115.165 42.9128C113.909 42.9128 113.142 42.637 112.863 42.0854C112.723 41.79 112.638 41.5284 112.609 41.3021C112.578 41.0758 112.564 40.7359 112.564 40.2823V35.1101C112.564 33.3965 111.946 32.5391 110.71 32.5391C110.011 32.5391 109.539 32.7658 109.289 33.219C109.04 33.6723 108.916 34.3123 108.916 35.14V40.3119C108.916 40.7848 108.9 41.1297 108.871 41.3463C108.841 41.5637 108.746 41.8192 108.587 42.1149C108.307 42.647 107.529 42.9128 106.254 42.9128C104.999 42.9128 104.231 42.637 103.952 42.0854C103.812 41.79 103.728 41.5284 103.698 41.3021C103.668 41.0758 103.653 40.7359 103.653 40.2823V35.1101C103.653 33.3965 103.034 32.5391 101.799 32.5391C100.603 32.5391 100.005 33.3965 100.005 35.1101V40.3415C100.005 40.7951 99.9902 41.1347 99.96 41.3613C99.9304 41.5879 99.8356 41.8488 99.6762 42.1442C99.377 42.6569 98.5994 42.9128 97.3433 42.9128C96.088 42.9128 95.3198 42.647 95.0414 42.1149C94.9015 41.8192 94.8168 41.5637 94.7872 41.3463C94.757 41.1297 94.7422 40.7848 94.7422 40.3119V29.9087C94.7422 29.4557 94.757 29.1158 94.7872 28.8888C94.8168 28.6629 94.911 28.4117 95.071 28.1355C95.3699 27.6035 96.0779 27.3373 97.1937 27.3373C98.3102 27.3373 99.0378 27.5148 99.377 27.8694C99.7152 28.2242 99.8853 28.7367 99.8853 29.4062Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.442 41.5238C137.063 41.2876 136.874 40.9134 136.874 40.4007C136.874 39.8886 137.252 39.1104 138.01 38.066C138.228 37.731 138.583 37.5635 139.071 37.5635C139.56 37.5635 140.222 37.8051 141.06 38.2879C141.897 38.7708 142.654 39.0117 143.332 39.0117C144.548 39.0117 145.156 38.7754 145.156 38.3026C145.156 37.9284 144.488 37.6522 143.153 37.4748C141.897 37.2974 140.651 36.8348 139.415 36.0858C138.837 35.7309 138.358 35.175 137.98 34.416C137.601 33.6577 137.411 32.7562 137.411 31.7118C137.411 28.5988 139.574 27.042 143.9 27.042C145.316 27.042 146.771 27.3773 148.266 28.0465C148.964 28.3625 149.313 28.7563 149.313 29.2292C149.313 29.702 149.093 30.2836 148.655 30.9731C148.216 31.6626 147.767 32.0072 147.309 32.0072C147.07 32.0072 146.626 31.8593 145.979 31.5636C145.331 31.2685 144.628 31.1206 143.871 31.1206C142.694 31.1206 142.106 31.3376 142.106 31.7709C142.106 32.3617 142.784 32.7462 144.139 32.9233C145.455 33.0616 146.731 33.4361 147.967 34.0465C148.545 34.3226 149.028 34.8001 149.417 35.48C149.806 36.1592 150 37.0122 150 38.0367C150 39.0612 149.806 39.9328 149.417 40.6519C149.028 41.3717 148.485 41.908 147.788 42.2629C146.511 42.8736 144.912 43.179 142.989 43.179C141.064 43.179 139.216 42.6274 137.442 41.5238Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.069 34.135C129.827 34.135 130.206 33.82 130.206 33.1889C130.206 32.7364 130.031 32.3718 129.682 32.0957C129.334 31.8199 128.835 31.682 128.188 31.682C127.539 31.682 126.896 31.9432 126.259 32.4652C125.621 32.9876 125.302 33.5442 125.302 34.135H129.069ZM135.379 33.2631C135.379 34.4749 135.074 35.3721 134.467 35.9527C133.858 36.5342 133.186 36.8246 132.448 36.8246H125.362C125.362 37.3766 125.691 37.8395 126.348 38.2136C127.006 38.5881 127.664 38.7752 128.322 38.7752C129.478 38.7752 130.385 38.6569 131.043 38.4203L131.372 38.3023C131.85 38.0857 132.249 37.9774 132.568 37.9774C133.206 37.9774 133.754 38.4203 134.213 39.3072C134.471 39.8392 134.601 40.2928 134.601 40.6666C134.601 42.4208 132.479 43.2968 128.233 43.2968C126.757 43.2968 125.447 43.0456 124.3 42.5435C123.154 42.041 122.262 41.3758 121.624 40.5483C120.368 38.9523 119.741 37.1695 119.741 35.199C119.741 32.7164 120.552 30.7319 122.177 29.2436C123.802 27.7566 125.83 27.0122 128.262 27.0122C131.033 27.0122 133.066 27.9782 134.362 29.9085C135.04 30.9333 135.379 32.0515 135.379 33.2631Z"
        fill="white"
      />
      <path
        d="M46.1599 13.986C46.2519 10.0923 46.0218 3.30692 43.5364 0.476197C42.1748 -1.07442 38.9377 1.5201 36.8435 3.52409C33.3724 1.84394 29.1074 1.0172 23.9218 1.0172C18.7363 1.0172 14.4751 1.84775 11.0078 3.52409C8.90596 1.51629 5.67266 -1.07823 4.31107 0.472388C1.82952 3.2993 1.59555 10.0618 1.68761 13.9593C0.567649 17.0072 0 20.5656 0 24.6307C0 33.1724 2.46237 39.4968 7.31424 43.4552C10.5821 46.1145 14.8509 47.6461 20.2896 48.1071C19.6529 48.4233 18.9932 48.7319 18.3144 49.0786L16.3429 50.0387L15.223 50.5835C14.1375 51.1473 13.0176 51.6464 11.8132 52.3817L11.5141 52.5379C11.4297 52.5989 11.3491 52.7018 11.3338 52.8161C11.2955 53.0332 11.445 53.2389 11.6637 53.2694C12.7606 53.4333 13.846 53.6466 14.9583 53.7419L15.7868 53.8333C16.0591 53.8638 16.3353 53.8866 16.6152 53.9019L18.2798 53.9933C20.5082 54.0314 22.752 53.9095 24.9766 53.5628C27.1973 53.2313 29.3797 52.6141 31.4777 51.7569C33.5642 50.8997 35.5625 49.7834 37.369 48.4233C40.9513 45.6611 43.7665 41.9427 45.4426 37.7976C46.0563 36.2965 46.5127 34.7383 46.8426 33.1572C47.5061 30.6198 47.8436 27.7777 47.8436 24.6307C47.8436 20.5808 47.276 17.03 46.1637 13.986H46.1599Z"
        fill="#6842FF"
      />
      <path
        d="M33.4568 47.0557C30.6991 47.8557 27.5348 48.252 23.918 48.252H23.9103C23.5689 48.252 23.2161 48.2482 22.8632 48.2367C22.1383 48.2215 21.4364 48.1872 20.7499 48.1377C20.5926 48.1262 20.4354 48.1148 20.2858 48.1034C19.6491 48.4158 18.9894 48.7282 18.3105 49.0749L16.3391 50.035C17.2711 50.0959 18.3719 50.1645 19.3499 50.1645C25.1453 50.1645 29.7901 49.1397 33.4568 47.0557Z"
        fill="#492EB3"
      />
      <path
        d="M23.9179 39.7064C33.0847 39.7064 39.1869 37.1462 39.1869 24.6308C39.1869 18.7217 37.8176 14.7328 35.0177 12.4392C32.6474 10.5038 29.0114 9.55518 23.9179 9.55518C18.8244 9.55518 15.1884 10.4962 12.818 12.4392C10.0105 14.729 8.6489 18.7255 8.6489 24.6308C8.6489 37.1462 14.7511 39.7064 23.9179 39.7064Z"
        fill="white"
      />
      <path
        d="M19.047 21.4269C19.047 20.3029 19.7988 19.3505 20.8305 19.0305C20.2629 18.5085 19.5034 18.1885 18.6673 18.1885C16.9145 18.1885 15.48 19.6057 15.48 21.3354V27.6293C15.48 29.359 16.9145 30.7763 18.6673 30.7763C20.4201 30.7763 21.8546 29.3628 21.8546 27.6293V23.9261C21.7664 23.9337 21.6782 23.9414 21.5899 23.9414C20.1823 23.9414 19.0432 22.8175 19.0432 21.4307L19.047 21.4269Z"
        fill="#6842FF"
      />
      <path
        d="M29.4334 21.1982C29.4334 20.1124 30.1315 19.1904 31.1095 18.8399C30.5725 18.4322 29.9013 18.1846 29.1726 18.1846C27.4198 18.1846 25.9853 19.6018 25.9853 21.3315V27.6254C25.9853 29.3551 27.4198 30.7723 29.1726 30.7723C30.9254 30.7723 32.3599 29.3589 32.3599 27.6254V23.6822C32.2371 23.7012 32.1106 23.7127 31.9802 23.7127C30.5725 23.7127 29.4334 22.5888 29.4334 21.202V21.1982Z"
        fill="#6842FF"
      />
    </svg>
  );
};

export default Logo;
