import React from 'react';

const Rise: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0833 16.6667C25.9327 16.6667 25 15.7339 25 14.5833C25 13.4327 25.9327 12.5 27.0833 12.5H43.75C44.9006 12.5 45.8333 13.4327 45.8333 14.5833V31.25C45.8333 32.4006 44.9006 33.3333 43.75 33.3333C42.5994 33.3333 41.6667 32.4006 41.6667 31.25V19.6129L28.5565 32.7231C27.7429 33.5367 26.4238 33.5367 25.6102 32.7231L18.75 25.8629L7.72313 36.8898C6.90954 37.7034 5.59044 37.7034 4.77685 36.8898C3.96326 36.0762 3.96326 34.7571 4.77685 33.9435L17.2769 21.4435C18.0904 20.6299 19.4095 20.6299 20.2231 21.4435L27.0833 28.3037L38.7204 16.6667H27.0833Z"
        fill="#957BFF"
      />
    </svg>
  );
};

export default Rise;
