import React from 'react';

const TikTokCircle: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
      <circle cx="17.5" cy="17.5" r="16.5" stroke="#2F3148" strokeWidth="2" />
      <g clipPath="url(#clip0_114_629)">
        <path
          d="M25.003 15.9247C23.4917 15.9283 22.0175 15.459 20.7886 14.5831V20.6917C20.7882 21.8231 20.4409 22.9274 19.7931 23.8569C19.1454 24.7864 18.228 25.4969 17.1638 25.8934C16.0995 26.2898 14.9391 26.3532 13.8377 26.0753C12.7362 25.7973 11.7463 25.1911 11.0001 24.3378C10.254 23.4845 9.78734 22.4248 9.66247 21.3002C9.53759 20.1757 9.76049 19.04 10.3014 18.0451C10.8422 17.0501 11.6753 16.2432 12.6891 15.7324C13.703 15.2215 14.8493 15.031 15.9748 15.1864V18.2588C15.4598 18.0975 14.9067 18.1023 14.3946 18.2727C13.8825 18.443 13.4376 18.7701 13.1233 19.2072C12.8091 19.6444 12.6416 20.1692 12.6447 20.7068C12.6479 21.2444 12.8216 21.7672 13.141 22.2006C13.4604 22.634 13.9092 22.9559 14.4233 23.1202C14.9374 23.2845 15.4905 23.2829 16.0035 23.1155C16.5166 22.9482 16.9635 22.6237 17.2803 22.1884C17.5972 21.7531 17.7678 21.2293 17.7678 20.6917V8.75H20.7886C20.7865 9.004 20.8079 9.25766 20.8524 9.50776C20.9574 10.0661 21.1757 10.5972 21.4939 11.0687C21.8121 11.5401 22.2235 11.942 22.703 12.2497C23.3853 12.6988 24.1851 12.9382 25.003 12.938V15.9247Z"
          fill="#F9FAFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_114_629">
          <rect width="15.3788" height="17.5" fill="white" transform="translate(9.62531 8.75)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TikTokCircle;
