import React from 'react';

const Arcade: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0033 6.66683C19.0828 6.66683 18.3366 7.41302 18.3366 8.3335C18.3366 9.25397 19.0828 10.0002 20.0033 10.0002C20.9237 10.0002 21.6699 9.25397 21.6699 8.3335C21.6699 7.41302 20.9237 6.66683 20.0033 6.66683ZM15.0033 8.3335C15.0033 5.57207 17.2418 3.3335 20.0033 3.3335C22.7647 3.3335 25.0033 5.57207 25.0033 8.3335C25.0033 10.5105 23.6119 12.3626 21.6699 13.049V14.3048C22.4171 14.4297 23.1536 14.6392 23.8649 14.9332L33.2487 18.8173L33.2528 18.819C33.5576 18.9441 33.8366 19.1211 34.0781 19.3405C34.6265 19.6133 35.0033 20.1794 35.0033 20.8335V28.7115C35.0193 29.1291 34.9445 29.5455 34.7838 29.9318C34.5145 30.5801 34.0145 31.1057 33.3805 31.4071L33.3768 31.4088L24.2736 35.7088C22.9384 36.3396 21.4799 36.6668 20.0033 36.6668C18.5266 36.6668 17.0683 36.3396 15.7331 35.7088L6.62782 31.4079C6.10827 31.1617 5.67563 30.7636 5.38704 30.2663C5.11154 29.7916 4.97971 29.2477 5.00659 28.7006L5.00359 21.5687C5.00017 21.5009 4.99919 21.4329 5.0007 21.3647C5.00127 21.3389 5.00219 21.3131 5.00347 21.2873L5.00328 20.8342C5.003 20.1842 5.37491 19.6209 5.91766 19.3459C6.16499 19.12 6.45188 18.9388 6.76576 18.8124C6.78297 18.8055 6.80026 18.7989 6.81762 18.7925L16.1408 14.9335C16.8521 14.6395 17.5893 14.4297 18.3366 14.3048V13.049C16.3946 12.3626 15.0033 10.5105 15.0033 8.3335ZM8.33827 24.7777L8.33985 28.5301L17.1568 32.6948C18.0468 33.1153 19.019 33.3334 20.0033 33.3334C20.9876 33.3334 21.9597 33.1153 22.8497 32.6949L31.6699 28.5286V24.7768L24.265 28.2244C22.9302 28.845 21.4752 29.1669 20.0032 29.1669C18.5312 29.1669 17.077 28.8454 15.7422 28.2248L8.33827 24.7777ZM17.4156 18.0135C17.7167 17.889 18.0245 17.7872 18.3366 17.708V21.6668C18.3366 22.5873 19.0827 23.3335 20.0032 23.3335C20.9237 23.3335 21.6699 22.5873 21.6699 21.6668V17.708C21.982 17.7872 22.2904 17.8893 22.5915 18.0137L30.9064 21.4554L22.8589 25.2022C21.9643 25.6181 20.9897 25.8336 20.0032 25.8336C19.0167 25.8336 18.0421 25.6181 17.1476 25.2022L9.10002 21.4554L17.4156 18.0135Z"
        fill="#957BFF"
      />
    </svg>
  );
};

export default Arcade;
