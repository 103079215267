import React from 'react';

const Video: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33334 8.33333C3.33334 6.49238 4.82573 5 6.66668 5H33.3333C35.1743 5 36.6667 6.49238 36.6667 8.33333V31.6667C36.6667 33.5076 35.1743 35 33.3333 35H6.66668C4.82573 35 3.33334 33.5076 3.33334 31.6667V8.33333ZM10 8.33333H6.66668V11.6667H10V8.33333ZM13.3333 8.33333V18.3333H26.6667V8.33333H13.3333ZM30 8.33333V11.6667H33.3333V8.33333H30ZM33.3333 15H30V18.3333H33.3333V15ZM33.3333 21.6667H30V25H33.3333V21.6667ZM33.3333 28.3333H30V31.6667H33.3333V28.3333ZM26.6667 31.6667V21.6667H13.3333V31.6667H26.6667ZM10 31.6667V28.3333H6.66668V31.6667H10ZM6.66668 25H10V21.6667H6.66668V25ZM6.66668 18.3333H10V15H6.66668V18.3333Z"
        fill="#957BFF"
      />
    </svg>
  );
};

export default Video;
