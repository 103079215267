import * as React from 'react';
import './PartnerAgencies.css';

const PartnerAgencies: React.FC = () => {
  return (
    <div className="PartnerAgencies">
      <h2>Partner agencies</h2>
      <p className="PartnerAgencies__Subtitle">
        We’ve partnered with some of the best agencies to help advertisers tap into a top-tier audience.
      </p>
      <div className="PartnerAgencies__Agencies">
        <div className="PartnerAgencies__Agencies__Box">
          <img src="/images/partner-agencies/hoopla-logo.png" alt="CrazyGames Partner agent Hoopla" />
        </div>
        <div className="PartnerAgencies__Agencies__Box">
          <img src="/images/partner-agencies/evolve-logo.png" alt="CrazyGames Partner agent Evolve" />
        </div>
        <div className="PartnerAgencies__Agencies__Box">
          <img src="/images/partner-agencies/jimmys-logo.png" alt="CrazyGames Partner agent Jimmy's" />
        </div>
        <div className="PartnerAgencies__Agencies__Box">
          <img src="/images/partner-agencies/batterypop-logo.png" alt="CrazyGames Partner agent Battery Pop" />
        </div>
      </div>
    </div>
  );
};

export default PartnerAgencies;
